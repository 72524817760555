import { LitElement, unsafeCSS, html } from 'lit';
import { customElement, property, state, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import styles from './drb-thumbnail.scss?inline';
import { onNextRepaint } from '~/shared/utils/animation';

@customElement('drb-thumbnail')
class DrbThumbnail extends LitElement {
  static styles = unsafeCSS(styles);
  isTouchDevice = matchMedia('(hover: none)').matches;

  @query('video') hoverVideoEl: HTMLVideoElement;
  @query('img') hoverImageEl: HTMLImageElement;

  @property({ attribute: 'hover-media-type', type: String })
  hoverMediaType?: 'gif' | 'video';

  @property({ attribute: 'hover-media-src', type: String })
  hoverMediaSrc: '';

  @property({ attribute: 'hover-media-src-large', type: String })
  hoverMediaSrcLarge: '';

  @state()
  hoverMediaInitialized = false;

  @state()
  showHoverMedia = false;

  @state()
  targetHoverMediaSrc = '';

  connectedCallback() {
    super.connectedCallback();
    if (!this.hoverMediaType || !this.hoverMediaSrc || this.isTouchDevice) return;

    this.addEventListener('mouseenter', () => {
      // set the target hover media src based on the width of the thumbnail (use large src if available on large thumbnails)
      if (!this.hoverMediaInitialized) {
        const mediaWidth = this.clientWidth;
        this.targetHoverMediaSrc = mediaWidth > 400 && this.hoverMediaSrcLarge ? this.hoverMediaSrcLarge : this.hoverMediaSrc;
      }

      // intialize hover media
      this.hoverMediaInitialized = true;

      // play video on hover
      if (this.hoverVideoEl) {
        this.hoverVideoEl.currentTime = 0;
        this.hoverVideoEl?.play();
      }

      // reset gif on hover
      this.hoverImageEl?.setAttribute('src', this.targetHoverMediaSrc);

      // show hover media (on next repaint to prevent flicker)
      onNextRepaint(() => {
        this.showHoverMedia = true;
      });
    });

    this.addEventListener('mouseleave', () => {
      // hide hover media
      this.showHoverMedia = false;

      // pause video on hover out
      this.hoverVideoEl?.pause();
    });
  }

  getHoverMediaHtml() {
    if (!this.hoverMediaInitialized) return html``;

    if (this.hoverMediaType === 'video') {
      return html`
        <video
          class="thumbnail__hover-media"
          src="${this.targetHoverMediaSrc}"
          autoplay
          muted
          loop
          playsinline
        ></video>
      `
    }

    return html`
      <img
        class="thumbnail__hover-media"
        src="${this.targetHoverMediaSrc}"
      />
    `
  }

  render() {
    return html`
      <div
        class="${classMap({
          'thumbnail': true,
          'thumbnail--show-hover-media': this.showHoverMedia
        })}"
      >
        ${this.getHoverMediaHtml()}
        <slot></slot>
      </div>
    `;
  }
}

export { DrbThumbnail };
