/* eslint-disable arrow-body-style */
import Flickity from 'flickity';
import 'flickity/css/flickity.css';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipeVideoPlugin from 'photoswipe-video-plugin';
import { PHOTOSWIPE_ICONS } from '~/shared/modules/photoswipe';
import { generateSrcset } from '~/shared/utils/media';
import { collapseElement, expandElement } from '~/shared/utils/expand-collapse';

export const ServiceDetailsDialog = {
  lightbox: null,

  init() {
    this.bindListeners();
  },

  bindListeners() {
    document.addEventListener('drb-dialog-content-updated', (e) => {
      const dialog = e.target.closest('drb-dialog#service-details-dialog');
      if (!dialog) return;

      this.setupFlickity(dialog);
      this.bindThumbnails(dialog);
      this.bindDescription(dialog);
      this.initLightbox(dialog);
      this.bindMilestonesBreakdown(dialog);
    });
  },

  setupFlickity(dialog) {
    const carousel = dialog.querySelector('[data-service-gallery-carousel]');
    if (!carousel) return;

    const hasMultipleImages = dialog.querySelectorAll('[data-service-gallery-gallery-thumbnail]').length > 1;
    const isTouchDevice = matchMedia('(hover: none)').matches;

    this.flkty = new Flickity(carousel, {
      wrapAround: true,
      contain: true,
      prevNextButtons: hasMultipleImages,
      pageDots: false,
      draggable: hasMultipleImages && isTouchDevice,
    });

    this.flkty.on('change', (index) => {
      // sync thumbnail buttons on change (dragging carousel or clicking thumbnails)
      dialog.querySelectorAll('[data-service-gallery-button]').forEach((button, buttonIndex) => {
        button.toggleAttribute('data-current', index === buttonIndex);
      });
    });

    // open lightbox when clicking a slide
    this.flkty.on('staticClick', (_event, _pointer, _cellElement, cellIndex) => {
      this.lightbox?.loadAndOpen(cellIndex);
    });
  },

  bindThumbnails(dialog) {
    dialog.querySelectorAll('[data-service-gallery-button]').forEach((button, buttonIndex) => {
      button.addEventListener('click', () => {
        this.flkty.selectCell(buttonIndex);
      });
    });
  },

  bindDescription(dialog) {
    const descriptionText = dialog.querySelector('[data-service-description]');
    if (descriptionText.scrollHeight > 240) {
      descriptionText.classList.add('service-details__description--scrolling');
    }
  },

  bindMilestonesBreakdown(dialog) {
    const milestonesBreakdownTrigger = dialog.querySelector('[data-milestones-breakdown-trigger]');
    const milestonesBreakdown = dialog.querySelector('[data-milestones-breakdown]');

    if (!milestonesBreakdownTrigger || !milestonesBreakdown) return;

    milestonesBreakdownTrigger.addEventListener('click', () => {
      this.toggleMilestonesBreakdown(milestonesBreakdown);
    });
  },

  toggleMilestonesBreakdown(milestonesBreakdown) {
    const isExpanded = milestonesBreakdown.hasAttribute('data-expanded');

    if (isExpanded) {
      collapseElement(milestonesBreakdown);
    } else {
      expandElement(milestonesBreakdown);
    }
  },

  initLightbox(dialog) {
    if (this.lightbox) this.lightbox.destroy();

    const dialogOuterSlot = dialog.querySelector('[slot="dialog-outer"]');
    if (!dialogOuterSlot) return;

    this.lightbox = new PhotoSwipeLightbox({
      secondaryZoomLevel: 'fill',
      doubleTapAction: false,
      arrowPrev: false,
      arrowNext: false,
      appendToEl: dialogOuterSlot,
      dataSource: [],
      ...PHOTOSWIPE_ICONS,
      pswpModule: () => import('photoswipe'),
    });

    // set photoswipe thumbEl to enable animations
    this.lightbox.addFilter('thumbEl', (thumbEl, data, index) => {
      return dialog.querySelectorAll('[data-lightbox-trigger]')[index] || thumbEl;
    });

    // sync flickity carousel with photoswipe
    this.lightbox.on('change', () => {
      const { currIndex } = this.lightbox.pswp;
      this.flkty.selectCell(currIndex);
    });

    new PhotoSwipeVideoPlugin(this.lightbox);
    this.lightbox.init();

    // set the lightbox item data
    dialog.querySelectorAll('[data-lightbox-trigger]').forEach((element) => {
      const lightboxItemData = this.getPhotoswipeData(element);
      if (lightboxItemData) this.lightbox.options?.dataSource?.push(lightboxItemData);
    });
  },

  getPhotoswipeData(element) {
    const mediaWidth = parseInt(element.getAttribute('data-media-width') || 400, 10);
    const mediaHeight = parseInt(element.getAttribute('data-media-height') || 300, 10);
    const mediaType = element.getAttribute('data-media-type');
    const lightboxUrl = element.getAttribute('data-lightbox-url');
    const previewUrl = element.getAttribute('data-preview-url');
    const fileName = element.getAttribute('data-file-name');

    const isValidLightboxItem = !!previewUrl && !!lightboxUrl && !!mediaHeight && !!mediaWidth;
    const isGif = fileName.endsWith('.gif');

    if (!isValidLightboxItem) return null;

    // video previews
    if (mediaType === 'video') {
      return {
        videoSrc: lightboxUrl,
        type: 'video',
        msrc: previewUrl,
        width: mediaWidth,
        height: mediaHeight,
        thumbCropped: true,
      };
    }

    // image previews
    const widths = [mediaWidth, 640, 850, 1024, 1600, 1905, 2400, 3810].filter((width) => width <= mediaWidth);

    const srcset = !isGif
      ? generateSrcset({ mediaUrl: lightboxUrl, aspectRatio: mediaWidth / mediaHeight, widths })
      : null;

    return {
      srcset,
      src: lightboxUrl,
      msrc: previewUrl,
      width: mediaWidth,
      height: mediaHeight,
      thumbCropped: true,
    };
  },
};
