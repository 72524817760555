/*
  A generic utility component that can be used to create expandable and
  collapsable content areas.

  ```
    <drb-expandable>
      Some text here <button data-toggle-expandable>Toggle Expandable Content</button>

      <div slot="expandable-content">
        <div>
          <p>Content</p>
          <p>Content</p>
          <p>Content</p>
        </div>
      </div>
    </drb-expandable>
  ```

  The element with `slot="expandable-content"` will be what gets expanded and
  collapsed. It's best to have a single root element within this slot to ensure
  that any padding is correctly accounted for.

  When any element within the component with a `data-toggle-expandable` attribute
  is clicked, the content will expand or collapse.

  You can also set the `data-toggle-expandable` attribute to `true` or `false` to
  force the content to expand or collapse respectively.

  Attributes:
    - `expanded` (boolean) - whether the content is expanded or collapsed.
*/

import { LitElement } from 'lit';
import {  html } from 'lit/static-html.js';
import { customElement, property } from 'lit/decorators.js';
import { expandElement, collapseElement } from '~/shared/utils/expand-collapse';
import { onNextRepaint } from '~/shared/utils/animation';

@customElement('drb-expandable')
class DrbExpandable extends LitElement {
  expandableContentEl: HTMLElement = this.querySelector('[slot="expandable-content"]');
  initialized = false;

  @property({ type: Boolean, reflect: true })
  expanded = false;

  connectedCallback() {
    super.connectedCallback();
    if (!this.expandableContentEl) return;

    this.addEventListener('click', (e) => {
      const toggleTrigger = (e.target as HTMLElement).closest('[data-toggle-expandable]');
      if (!toggleTrigger) return;

      // If the clicked element has a [data-toggle-expandable] attribute, toggle the expandable content,
      // or force it to expand or collapse based on the value of the attribute (for more granular control).
      const toggleType = toggleTrigger?.getAttribute('data-toggle-expandable');
      const force = toggleType === 'true' ? true : toggleType === 'false' ? false : undefined;
      this.toggle(force);
    });
  }

  toggle(force?) {
    this.expanded = force !== undefined ? force : !this.expanded;
  }

  updated(changedProperties) {
    if (changedProperties.has('expanded')) {
      // Prevent expanding/collapsing on the first render
      if (!this.initialized) return;

      // When expanded is updated, expand or collapse the content
      if (this.expanded) {
        expandElement(this.expandableContentEl);
      } else {
        collapseElement(this.expandableContentEl);
      }

      this.querySelector('[data-toggle-expandable]')?.setAttribute('aria-expanded', this.expanded.toString());
    }

    // Ensure collapsed elements cannot be focused
    this.expandableContentEl.toggleAttribute('inert', !this.expanded);
  }

  firstUpdated() {
    onNextRepaint(() => {
      // Set initial height to animate from (so transitions work on the first expand/collapse)
      if (this.expanded) {
        this.expandableContentEl.style.height = 'auto';
      } else {
        this.expandableContentEl.style.height = '0px';
      }

      this.initialized = true;
    });
  }

  render() {
    return html`
      <slot></slot>
      <slot name="expandable-content"></slot>
    `;
  }
}

export { DrbExpandable };
